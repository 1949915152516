import React, { createContext, useState, useRef } from 'react';
import { InvoicePFConfig, InvoicePJConfig, UserCard } from '../shared/Interfaces';


interface PaymentContextProps {
    paymentStage: string; // methods, add-card, payment, payment-saved-card
    setPaymentStage: (stage: string) => void;
    paymentOption: string; // automatic, cards-only, single
    setPaymentOption: React.Dispatch<React.SetStateAction<string>>;
    card: UserCard;
    setCard: React.Dispatch<React.SetStateAction<UserCard>>;
    paymentToast: { [key: string]: string };
    setPaymentToast: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
    paymentMethod: string; // card, boleto
    setPaymentMethod: React.Dispatch<React.SetStateAction<string>>;
    isPj: boolean;
    setIsPj: React.Dispatch<React.SetStateAction<boolean>>;
    paymentStageToReturn: string; // After invoice config, return to this stage
    setPaymentStageToReturn: React.Dispatch<React.SetStateAction<string>>;
    invoiceType: string;
    setInvoiceType: React.Dispatch<React.SetStateAction<string>>;
    invoiceConfig: InvoicePFConfig | InvoicePJConfig;
    setInvoiceConfig: React.Dispatch<React.SetStateAction<InvoicePFConfig | InvoicePJConfig>>;
}

export const PaymentContext = createContext<PaymentContextProps>({
    paymentStage: "",
    setPaymentStage: () => { },
    paymentOption: "",
    setPaymentOption: () => { },
    card: { payment_id: "", card_brand: "", card_last4: "", card_expiration: "", billing_name: "" },
    setCard: () => { },
    paymentToast: {},
    setPaymentToast: () => { },
    paymentMethod: "",
    setPaymentMethod: () => { },
    isPj: false,
    setIsPj: () => { },
    paymentStageToReturn: "",
    setPaymentStageToReturn: () => { },
    invoiceType: "",
    setInvoiceType: () => { },
    invoiceConfig: {} as InvoicePFConfig | InvoicePJConfig,
    setInvoiceConfig: () => { },
});

export const PaymentProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const [paymentStage, setPaymentStage] = useState<string>('');
    const [paymentOption, setPaymentOption] = useState<string>('');
    const [card, setCard] = useState<UserCard>({ payment_id: "", card_brand: "", card_last4: "", card_expiration: "", billing_name: "" });
    const [paymentToast, setPaymentToast] = useState<{ [key: string]: string }>({});
    const [paymentMethod, setPaymentMethod] = useState<string>('card');
    const [isPj, setIsPj] = useState<boolean>(false);
    const [paymentStageToReturn, setPaymentStageToReturn] = useState<string>('');
    const [invoiceType, setInvoiceType] = useState<string>('');
    const [invoiceConfig, setInvoiceConfig] = useState<InvoicePFConfig | InvoicePJConfig>({} as InvoicePFConfig | InvoicePJConfig);

    return (
        <PaymentContext.Provider value={{
            paymentStage, 
            setPaymentStage,
            paymentOption, 
            setPaymentOption, 
            card, 
            setCard, 
            paymentToast, 
            setPaymentToast,
            paymentMethod,
            setPaymentMethod,
            isPj,
            setIsPj,
            paymentStageToReturn,
            setPaymentStageToReturn,
            invoiceType,
            setInvoiceType,
            invoiceConfig,
            setInvoiceConfig,
        }}>
            {children}
        </PaymentContext.Provider>
    );
};
