import { styled } from "styled-components";
import BusinessIcon from "@mui/icons-material/Business";
import PersonIcon from "@mui/icons-material/Person";
import { useContext, useState } from "react";
import { PaymentContext } from "../../contexts/PaymentContext";


export default function InvoiceType() {
  const { setIsPj, setPaymentStage } = useContext(PaymentContext);

  function handleClick(isPj: boolean) {
    setIsPj(isPj);
    setPaymentStage("invoice-config");
  }

  return (
    <ContentContainer>
      <Desc>Escolha como deseja emitir suas notas fiscais.</Desc>
      <OptionsContainer>
        <OptionBox color="#007AFF" onClick={() => handleClick(false)}>
          <PersonIcon />
          <OptionText>Pessoa Física</OptionText>
        </OptionBox>
        <OptionBox color="#FF5722" onClick={() => handleClick(true)}>
          <BusinessIcon />
          <OptionText>Pessoa Jurídica</OptionText>
        </OptionBox>
      </OptionsContainer>
    </ContentContainer>
  )
}


const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 25px 20px 30px;
  max-width: 600px;
`;

const Desc = styled.p`
  font-size: 1.1rem;
  color: ${(props) => props.theme.desc_color};
  margin-top: 10px;
  text-align: justify;
`;

const OptionsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 5px;
`;

const OptionBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;  
  border: 2px solid ${(props) => props.color};
  color: ${(props) => props.color};
  cursor: pointer;
  padding: 0.75rem 1rem;
  border-radius: 6px;
  text-align: center;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #f9f9f9;
  }
`;

const OptionText = styled.span`
  font-size: 1rem;
  font-weight: 500;
`;
