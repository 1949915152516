import { useContext, useState } from 'react';
import { styled } from 'styled-components';
import { PaymentContext } from '../../contexts/PaymentContext';
import { getCardBrandIcon } from '../../shared/Utils';
import CircularProgress from '@mui/material/CircularProgress';
import { API_BASE } from '../../shared/Constants';
import CheckIcon from '../../assets/check.svg';
import { useAuth0 } from '@auth0/auth0-react';


export default function SavedCardPayment() {
    const [singleValue, setSingleValue] = useState(''); // Value to be paid (single payment)
    const [creditsToLoad, setCreditsToLoad] = useState(''); // Value to be loaded (automatic payment)
    const [minValue, setMinValue] = useState(''); // Minimum automatic payment value
    const [inputMessage, setInputMessage] = useState('');
    const { card, setPaymentStage, paymentOption, setPaymentToast } = useContext(PaymentContext);
    const [success, setSuccess] = useState(false);
    const [processing, setProcessing] = useState(false);
    const { getAccessTokenSilently } = useAuth0();

    const formatValue = (inputValue: string) => {
        // Remove any non-digit characters
        inputValue = inputValue.replace(/\D/g, '');

        // Limit the input to 8 digits (max 1000.00)
        if (inputValue.length > 8) {
            inputValue = inputValue.slice(0, 8);
        }

        // Pad with leading zeros if necessary
        while (inputValue.length < 3) {
            inputValue = '0' + inputValue;
        }

        // Format the value as reals and cents
        const reals = inputValue.slice(0, -2);
        const cents = inputValue.slice(-2);
        return `${parseInt(reals, 10)}.${cents}`;
    };

    const handleInputChange = (e: any, setter: any) => {
        const inputValue = e.target.value;
        const formattedValue = formatValue(inputValue);
        if (parseFloat(formattedValue) > 30000) {
            setInputMessage('O valor máximo é R$ 30000,00');
            setter(30000);
        } else {
            setInputMessage('');
            setter(formattedValue);
        }
    };

    const getCardExpiration = (expiration: string) => {
        const [month, year] = expiration.split('/');
        return month + '/' + year.slice(-2);
    }

    const handleSinglePayment = async () => {
        if (processing) {
            return;
        }
        if (singleValue === '' || parseFloat(singleValue) === 0) {
            setInputMessage('O valor não pode ser 0');
            return;
        }
        else if (parseFloat(singleValue) < 5) {
            setInputMessage('O valor mínimo é R$ 5,00');
            return;
        }

        const url = `${API_BASE}/api/billing/charge-saved-card?payment_id=${card.payment_id}&amount=${singleValue}`;
        const access_token = await getAccessTokenSilently();
        setProcessing(true);
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Authorization": `Auth ${access_token}`,
                "Content-Type": "application/json",
            }
        });
        
        if (response.ok) {
            const data = await response.json();
            if (data['status'] === 'success') {
                setPaymentToast({type: "success", message: "Pagamento efetuado com sucesso!"});
                setSuccess(true);
            }
            else if (data['status'] === 'requires_action') {
                window.location.href = data['action_url'];
            }
        } else {
            setPaymentToast({type: "error", message: "Não conseguimos cobrar com o cartão selecionado. Por favor, tente (re)cadastrar o cartão."});
            setPaymentStage("payment");
        }
        setProcessing(false);
    }

    const handleAutomaticConfig = async () => {
        if (processing) {
            return;
        }
        // check if minValue is greather than 0
        if (minValue === '' || parseFloat(minValue) === 0) {
            setInputMessage('O valor mínimo não pode ser 0');
            return;
        }
        // check if creditsToLoad is greather than 5 and less than 1000
        if (creditsToLoad === '' || parseFloat(creditsToLoad) < 5) {
            setInputMessage('O valor mínimo de recarga é R$ 5,00');
            return;
        } else if (parseFloat(creditsToLoad) > 10000) {
            setInputMessage('O valor máximo de recarga é R$ 10000,00');
            return;
        }

        const url = `${API_BASE}/api/billing/config-auto-charge?amount_to_load=${creditsToLoad}&threshold=${minValue}&payment_id=${card.payment_id}`;
        const access_token = await getAccessTokenSilently();
        setProcessing(true);
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Authorization": `Auth ${access_token}`,
                "Content-Type": "application/json",
            }
        });
        if (response.ok) {
            setSuccess(true);
        }
        else {
            setPaymentToast({type: "error", message: "Não foi possível configurar o pagamento automático. Por favor, tente novamente mais tarde."});
        }
        setProcessing(false);
    }

    return (
        <>
            {!success && (
                paymentOption === "single" ?
                    <ContentContainer>
                        <Desc>Escolha o valor a ser carregado em seu saldo.</Desc>
                        <Label>
                            Créditos
                        </Label>
                        <InputWrapper>
                            <RealSign>R$</RealSign>
                            <Input
                                type="text"
                                value={singleValue}
                                onChange={(e) => handleInputChange(e, setSingleValue)}
                                placeholder="0.00"
                            />
                        </InputWrapper>
                        {inputMessage !== '' && <InputMessage>{inputMessage}</InputMessage>}
                        <Label>
                            Método de pagamento
                        </Label>
                        <Card>
                            <CardIcon src={getCardBrandIcon(card.card_brand)} alt="Card Icon"/>
                            <CardNumber>**** **** **** {card.card_last4}</CardNumber>
                            <CardExpiration>{getCardExpiration(card.card_expiration)}</CardExpiration>
                        </Card>
                        <ButtonsContainer>
                            <Button onClick={handleSinglePayment}>
                                {
                                    processing ? <CircularProgress size={20} color="inherit" /> : "Pagar"
                                }
                            </Button>
                        </ButtonsContainer>
                    </ContentContainer>
                    : // Automatic payment
                    <ContentContainer>
                        <Desc>Escolha o valor a ser carregado quando seu saldo estiver abaixo de um determinado valor.</Desc>
                        <Label>
                            Carregar
                        </Label>
                        <InputWrapper>
                            <RealSign>R$</RealSign>
                            <Input
                                type="text"
                                value={creditsToLoad}
                                onChange={(e) => handleInputChange(e, setCreditsToLoad)}
                                placeholder="0.00"
                            />
                        </InputWrapper>
                        <Label>
                            Quando o valor estiver abaixo de
                        </Label>
                        <InputWrapper>
                            <RealSign>R$</RealSign>
                            <Input
                                type="text"
                                value={minValue}
                                onChange={(e) => handleInputChange(e, setMinValue)}
                                placeholder="0.00"
                            />
                        </InputWrapper>
                        {inputMessage !== '' && <InputMessage>{inputMessage}</InputMessage>}
                        <Label>
                            Método de pagamento
                        </Label>
                        <Card>
                            <CardIcon src={getCardBrandIcon(card.card_brand)} alt="Card Icon"/>
                            <CardNumber>**** **** **** {card.card_last4}</CardNumber>
                            <CardExpiration>{getCardExpiration(card.card_expiration)}</CardExpiration>
                        </Card>
                        <ButtonsContainer>
                            <Button onClick={handleAutomaticConfig}>
                                {
                                    processing ? <CircularProgress size={20} color="inherit" />
                                    : paymentOption === "single" ? "Pagar" : "Cadastrar"
                                }
                            </Button>
                        </ButtonsContainer>
                    </ContentContainer>
            )}
            {success && 
                <SucessContainer>
                    <SuccessIcon src={CheckIcon} alt="Success Icon"/>
                    <SuccessMessage>{paymentOption === "single" ? "Pagamento efetuado com sucesso!" : "Pagamento automático configurado com sucesso!"}</SuccessMessage>
                </SucessContainer>
            }
        </>
    )
}

const Desc = styled.p`
    font-size: 1.1rem;
    color: ${props => props.theme.desc_color};
    margin: 0px;
    margin-top: 10px;
    flex-wrap: wrap;
    text-align: justify;
    width: 350px;
`;

const SucessContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px;
`;

const SuccessIcon = styled.img`
    width: 70px;
    height: 70px;
`;

const SuccessMessage = styled.p`
    font-size: 1.3rem;
    font-weight: 600;
    color: ${props => props.theme.desc_color};
    margin: 0px;
`;

const InputMessage = styled.p`
    font-size: 1rem;
    color: ${props => props.theme.red};
    margin: 0px;
    margin-top: 10px;
    margin-left: 2px;
`;

const CardNumber = styled.p`
    font-size: 1.1rem;
    font-weight: 500;
    color: ${props => props.theme.desc_color};
    margin: 0px;
    margin-right: 15px;
`;

const CardExpiration = styled.p`
    font-size: 1.1rem;
    font-weight: 500;
    color: ${props => props.theme.desc_color};
    margin: 0px;
`;

const CardIcon = styled.img`
    width: 30px;
    height: 30px;
    margin: 10px;
`;

const Card = styled.div`
    height: 50px;
    width: 80%;
    border: 1px solid ${props => props.theme.white_divider};
    border-radius: 15px;
    display: flex;
    align-items: center;
`;

const ButtonsContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 0px;
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 5px 25px 20px 30px;
    height: 100%;
`;

const Label = styled.p`
    font-size: 1.1rem;
    font-weight: 500;
    color: ${props => props.theme.gray_hover};
    margin-bottom: 10px;
    margin-left: 3px;
    padding: 0px;
`;

const InputWrapper = styled.div`
    display: flex;
    width: 80%;
    align-items: center;
`;

const RealSign = styled.span`
    font-size: 1.2rem;
    padding: 10px;
    padding-right: 0px;
    height: 22px;
    border: 1px solid ${props => props.theme.white_divider};
    border-right: none;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    display: flex;
    align-items: center; /* Center the content vertically */
`;

const Input = styled.input`
    width: 100%;
    padding: 10px;
    height: 22px;
    border: 1px solid ${props => props.theme.white_divider};
    outline: none;
    font-size: 1.4rem;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    border-left: none;
`;

const Button = styled.button`
    background-color: ${props => props.theme.green};
    color: white;
    border: none;
    border-radius: 10px;
    padding: 10px 30px;
    width: 100%;
    font-size: 1.1rem;
    font-weight: 600;
    font-family: Plus Jakarta Sans, sans-serif;
    cursor: pointer;

    &:hover {
        background-color: ${props => props.theme.green_hover};
    }

    &:active {
        background-color: ${props => props.theme.green};
    }
`
