import Modal from '@mui/material/Modal';
import { useContext, useEffect, useState } from 'react';
import { styled } from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import { PaymentContext } from '../../contexts/PaymentContext';
import PaymentMethod from './PaymentMethod';
import Checkout from './Checkout';
import SavedCardPayment from './SavedCardPayment';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { toast } from 'react-toastify';
import { TOASTPROPS } from '../../shared/Constants';
import InvoiceType from './InvoiceType';
import UserInvoiceConfig from './UserInvoiceConfig';


const paymentStageTitle: {[key: string]: string} = {
    "methods": "Métodos de pagamento",
    "add-card": "Adicionar cartão",
    "payment": "Pagamento",
    "payment-saved-card": "Pagamento",
    "invoice-type": "Dados Fiscais",
    "invoice-config": "Dados Fiscais - Cadastro",
};

const previousPaymentStage: {[key: string]: string} = {
    "add-card": "methods",
    "payment": "methods",
    "payment-saved-card": "methods",
    "invoice-config": "invoice-type",
};


export default function PaymentModal() {
    const { 
        paymentStage, 
        setPaymentStage, 
        paymentToast, 
        setPaymentToast,
    } = useContext(PaymentContext);

    // Used to handle toast messages among all subcomponents
    useEffect(() => {
        const showToast = () => {
            if (paymentToast) {
                switch (paymentToast.type) {
                    case "error":
                        toast.error(paymentToast.message, TOASTPROPS);
                        break;
                    case "success":
                        toast.success(paymentToast.message, TOASTPROPS);
                        break;
                    case "info":
                        toast.info(paymentToast.message, TOASTPROPS);
                        break;
                }
            }
        }
        showToast();
    }, [paymentToast]);

    const handleClose = () => {
        setPaymentToast({});
        setPaymentStage("refresh");
    }

    const handleReturn = () => {
        setPaymentStage(previousPaymentStage[paymentStage]);
    }

    const checkReturnButton = () => {
        return previousPaymentStage.hasOwnProperty(paymentStage);
    }

    return (
        <>
            <StyledModal
                open={paymentStage !== "" && paymentStage !== "refresh"}
                onClose={handleClose}
                BackdropProps={{onClick: (event) => event.stopPropagation()}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Container>
                    <TopContainer>
                        <TitleContainer>
                            {checkReturnButton() && <StyledReturnButton onClick={handleReturn}/>}
                            <Title>{paymentStageTitle[paymentStage]}</Title>
                        </TitleContainer>
                        <CloseButton type="button" onClick={handleClose}>
                            <CloseIcon/>
                        </CloseButton>
                    </TopContainer>
                    <HorizontalDivider/>
                    {paymentStage === "methods" && <PaymentMethod/>}
                    {paymentStage === "add-card" && <Checkout setup={true}/>}
                    {paymentStage === "payment" && <Checkout setup={false}/>}
                    {paymentStage === "payment-saved-card" && <SavedCardPayment/>}
                    {paymentStage === "invoice-type" && <InvoiceType/>}
                    {paymentStage === "invoice-config" && <UserInvoiceConfig/>}
                </Container>
            </StyledModal>
        </>
    );
}

const StyledReturnButton = styled(KeyboardReturnIcon)`
    cursor: pointer;
    margin-right: 10px;
    &:hover {
        color: ${props => props.theme.gray_hover};
    }
`;

const HorizontalDivider = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${props => props.theme.white_divider};
`;

const CloseButton = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    &:hover {
        color: ${props => props.theme.gray_hover};
    }
`;

const TitleContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const TopContainer = styled.div`
    display: flex;
    padding: 10px 30px 0px 40px;
    justify-content: space-between;
    height: 10%;
`;

const Title = styled.h1`
    font-size: 1.3rem;
    font-weight: 600;
    color: black;
    font-family: 'Roboto', sans-serif;
    text-align: center;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 15px;
    max-width: clamp(0px, 80%, 800px);
    min-width: 400px;
    max-height: 800px;
    outline: none;
    box-shadow: none;
`;

const StyledModal = styled(Modal)`
    display: flex;
    justify-content: center;
    align-items: center;
`;
