import { useContext, useEffect, useState } from "react";
import InputMask from "react-input-mask";
import CircularProgress from '@mui/material/CircularProgress';
import Skeleton from "@mui/material/Skeleton";
import { PaymentContext } from "../../contexts/PaymentContext";
import { styled } from "styled-components";
import { API_BASE } from "../../shared/Constants";
import { useAuth0 } from "@auth0/auth0-react";
import { InvoiceConfig, InvoicePFConfig, InvoicePJConfig } from "../../shared/Interfaces";

export interface Locations {
  [state: string]: {
    [city: string]: string;
  };
}

export default function UserInvoiceConfig() {
  const { isPj, setPaymentToast, invoiceConfig } = useContext(PaymentContext);
  const { getAccessTokenSilently } = useAuth0();
  const [locations, setLocations] = useState<Locations>({});
  const [selectedState, setSelectedState] = useState(invoiceConfig ? invoiceConfig.state : "");
  const [selectedCity, setSelectedCity] = useState(invoiceConfig ? invoiceConfig.city : "");
  const [loading, setLoading] = useState(true); // Add loading state
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(`${API_BASE}/api/states-cities`, {
          headers: { Authorization: `Auth ${await getAccessTokenSilently()}` },
        });
        if (response.ok) {
          setLocations(await response.json());
        }
      } catch {
        setPaymentToast({ message: "Erro ao carregar cidades e estados.", type: "error" });
      } finally {
        setLoading(false); // Set loading to false after data is fetched
      }
    })();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (!locations[selectedState]?.hasOwnProperty(selectedCity)) {
      setSelectedCity("");
    }
  }, [selectedState, selectedCity, locations]);

  const handleSend = async () => {
    if (saving === true) return;

    const highlightSelect = (id: string, value: string) => {
      const el = document.getElementById(id);
      if (el) {
        el.style.border = value ? "" : "2px solid red";
      }
    };

    const requiredIds = isPj
      ? ["cnpj", "email", "razao-social", "address", "cep", "address-number", "neighborhood"]
      : ["cpf", "email", "name", "address", "cep", "address-number", "neighborhood"];

    const data: any = {};
    let isValid = true;

    requiredIds.forEach((id) => {
      const input = document.getElementById(id) as HTMLInputElement;
      const value = input?.value.trim() || "";
      data[id === "razao-social" ? "razao_social" : id.replace("-", "_")] = value;

      if (!value) {
        input.style.border = "2px solid red";
        isValid = false;
      } else {
        input.style.border = "";
      }
    });

    highlightSelect("state", selectedState);
    highlightSelect("city", selectedCity);

    const emailInput = document.getElementById("email") as HTMLInputElement;
    const emailValue = emailInput?.value.trim() || "";
    data.email = emailValue;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailValue || !emailRegex.test(emailValue)) {
      emailInput.style.border = "2px solid red";
      isValid = false;
    } else {
      emailInput.style.border = "";
    }

    const isValidCpf = !isPj && /^\d{3}\.\d{3}\.\d{3}-\d{2}$/.test(data.cpf);
    const isValidCnpj = isPj && /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/.test(data.cnpj);
    const isValidCep = /^\d{5}-\d{3}$/.test(data.cep);

    if (isPj && !isValidCnpj) {
      document.getElementById("cnpj")?.setAttribute("style", "border: 2px solid red;");
      isValid = false;
    } else if (!isPj && !isValidCpf) {
      document.getElementById("cpf")?.setAttribute("style", "border: 2px solid red;");
      isValid = false;
    }

    if (!isValidCep) {
      document.getElementById("cep")?.setAttribute("style", "border: 2px solid red;");
      isValid = false;
    }

    if (!isValid || !selectedState || !selectedCity) {
      setPaymentToast({ message: "Preencha todos os campos corretamente.", type: "error" });
      return;
    }

    const invoiceConfig: InvoiceConfig = {
      invoice_type: isPj ? "PJ" : "PF",
      invoice_config: { ...data, state: selectedState, city: selectedCity },
    };

    setSaving(true);
    const response = await fetch(`${API_BASE}/api/billing/save-invoice-config`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Auth ${await getAccessTokenSilently()}`,
      },
      body: JSON.stringify(invoiceConfig),
    });

    if (response.ok) {
      setPaymentToast({ message: "Configuração de nota fiscal salva com sucesso.", type: "success" });
    } else if (response.status === 400) {
      setPaymentToast({ message: (await response.json()).status, type: "error" });
    } else {
      setPaymentToast({ message: "Erro ao salvar configuração de nota fiscal.", type: "error" });
    }
    setSaving(false); 
  };

  return (
    <Wrapper>
      <ContentContainer>
        <Desc>Informe os dados para emissão de notas fiscais.</Desc>

        {isPj ? (
          <>
            <FormRow>
              <FormLabel>CNPJ</FormLabel>
              <FormInput
                as={InputMask}
                id="cnpj"
                mask="99.999.999/9999-99"
                maskChar=""
                placeholder="XX.XXX.XXX/XXXX-XX"
                defaultValue={isPj && invoiceConfig ? (invoiceConfig as InvoicePJConfig).cnpj : ""}
              />
            </FormRow>
            <FormRow>
              <FormLabel>Razão Social</FormLabel>
              <FormInput
                id="razao-social"
                type="text"
                placeholder="Razão Social"
                defaultValue={isPj && invoiceConfig ? (invoiceConfig as InvoicePJConfig).razao_social : ""}
              />
            </FormRow>
          </>
        ) : (
          <>
            <FormRow>
              <FormLabel>CPF</FormLabel>
              <FormInput
                as={InputMask}
                id="cpf"
                mask="999.999.999-99"
                maskChar=""
                placeholder="XXX.XXX.XXX-XX"
                defaultValue={!isPj && invoiceConfig ? (invoiceConfig as InvoicePFConfig).cpf : ""}
              />
            </FormRow>
            <FormRow>
              <FormLabel>Nome</FormLabel>
              <FormInput
                id="name"
                type="text"
                placeholder="Nome"
                defaultValue={!isPj && invoiceConfig ? (invoiceConfig as InvoicePFConfig).name : ""}
              />
            </FormRow>
          </>
        )}
        <FormContainer>
          <FormRow>
            <FormLabel>Email</FormLabel>
            <FormInput
              id="email"
              type="email"
              placeholder="Email"
              defaultValue={invoiceConfig ? invoiceConfig.email : ""}
            />
          </FormRow>
          <FormRowSelect>
            <FormLabel>Estado</FormLabel>
            {loading ? (
              <Skeleton variant="rectangular" width="100%" height={20} />
            ) : (
              <FormSelect
                id="state"
                value={selectedState}
                onChange={(e) => setSelectedState(e.target.value)}
              >
                <option value="">Selecione o estado</option>
                {Object.keys(locations).map((uf) => (
                  <option key={uf} value={uf}>
                    {uf}
                  </option>
                ))}
              </FormSelect>
            )}
          </FormRowSelect>

          <FormRowSelect>
            <FormLabel>Cidade</FormLabel>
            {loading ? (
              <Skeleton variant="rectangular" width="100%" height={20} />
            ) : (
              <FormSelect
                id="city"
                value={selectedCity}
                onChange={(e) => setSelectedCity(e.target.value)}
              >
                <option value="">Selecione a cidade</option>
                {selectedState &&
                  Object.keys(locations[selectedState] || {}).map((city) => (
                    <option key={city} value={city}>
                      {city}
                    </option>
                  ))}
              </FormSelect>
            )}
          </FormRowSelect>

          <FormRow>
            <FormLabel>Endereço</FormLabel>
            <FormInput
              id="address"
              type="text"
              placeholder="Endereço"
              defaultValue={invoiceConfig ? invoiceConfig.address : ""}
            />
          </FormRow>

          <FormRow>
            <FormLabel>Número</FormLabel>
            <FormInput
              id="address-number"
              type="text"
              placeholder="Número"
              defaultValue={invoiceConfig ? invoiceConfig.address_number : ""}
            />
          </FormRow>

          <FormRow>
            <FormLabel>Bairro</FormLabel>
            <FormInput
              id="neighborhood"
              type="text"
              placeholder="Bairro"
              defaultValue={invoiceConfig ? invoiceConfig.neighborhood : ""}
            />
          </FormRow>

          <FormRow>
            <FormLabel>Complemento</FormLabel>
            <FormInput
              id="address-complement"
              type="text"
              placeholder="Complemento"
              defaultValue={invoiceConfig ? invoiceConfig.address_complement : ""}
            />
          </FormRow>

          <FormRow>
            <FormLabel>CEP</FormLabel>
            <FormInput
              as={InputMask}
              id="cep"
              mask="99999-999"
              maskChar=""
              placeholder="XXXXX-XXX"
              defaultValue={invoiceConfig ? invoiceConfig.cep : ""}
            />
          </FormRow>
        </FormContainer>
      </ContentContainer>

      <ButtonContainer>
        <SendButton onClick={handleSend}>
          {saving? <CircularProgress size={20} color="inherit" /> : "Salvar Dados"}
        </SendButton>
      </ButtonContainer>
    </Wrapper>
  );
}

// Styled components remain unchanged
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 25px 20px 30px;
  overflow-y: auto;
  max-height: 450px;
`;

const Desc = styled.p`
  font-size: 1.1rem;
  color: ${(props) => props.theme.desc_color};
  margin-top: 10px;
  text-align: justify;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormRow = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 90%;
`;

const FormRowSelect = styled(FormRow)`
  width: 95%;
`;

const FormLabel = styled.p`
  font-size: 0.9rem;
  font-weight: 500;
  color: ${(props) => props.theme.gray_hover};
  margin: 10px 0 2px;
`;

const FormInput = styled.input`
  width: 100%;
  padding: 8px;
  font-size: 1rem;
  border: 1px solid ${(props) => props.theme.white_divider};
  border-radius: 10px;
  &:focus {
    border: 1px solid ${(props) => props.theme.gray_hover};
  }
`;

const FormSelect = styled.select`
  width: 100%;
  height: 30px;
  padding-left: 5px;
  font-size: 1.1rem;
  border: 1px solid ${(props) => props.theme.white_divider};
  border-radius: 10px;
  background-color: #fff;
  &:focus {
    border: 1px solid ${(props) => props.theme.gray_hover};
  }
  &:disabled {
    background-color: #f0f0f0;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
`;

const SendButton = styled.button`
  font-size: 1.1rem;
  font-weight: 600;
  padding: 9px;
  width: 100%;
  background-color: ${(props) => props.theme.light_blue};
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    filter: brightness(0.9);
  }
  &:active {
    filter: brightness(0.8);
  }
`;
